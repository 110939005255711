import { useProcessCreate } from '../hooks/useProcessCreate';
import { useProcesses } from '../hooks/useProcesses';
import { debug } from '../services/debug';
import { type Database } from '../supabase/schema';
import * as icons from './Icons';
import {
  Button,
  Divider,
  Group,
  Modal,
  Space,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Process = Database['public']['Tables']['processes']['Row'];

type ProcessPropsRefApi = {
  openNew: (parentId: null | string | undefined) => void;
};

const ProcessPropsDialog = React.forwardRef<ProcessPropsRefApi | undefined>(
  (props, ref) => {
    const { createProcess } = useProcessCreate();
    const [mode, setMode] = React.useState<'closed' | 'open'>('closed');
    const [parentId, setParentId] = React.useState<null | string | undefined>(
      null,
    );
    const { invalidate } = useProcesses({ parentId });
    const {
      formState: { errors },
      handleSubmit,
      register,
      reset,
      setValue,
    } = useForm();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
      openNew: (id) => {
        setMode('open');
        setParentId(id);
        reset();
        setValue('title', '');
        setValue('is_parent', false);
      },
    }));

    const submitHandler = handleSubmit(async (data) => {
      const subject = 'creating process';
      const newProcess: Partial<Process> = {
        [`is_parent`]: data.is_parent,
        [`parent_id`]: parentId,
        title: data.title,
      };
      debug(subject, newProcess);
      const response = await createProcess({ data: newProcess });
      if (response.error) {
        debug(`error ${subject}`, response.error);
        showNotification({
          ...icons.failure,
          message: String(response.error),
        });
      } else {
        debug(`success ${subject}`);
        showNotification({
          ...icons.success,
          message: 'OK!',
        });
        invalidate();
        setMode('closed');
        navigate(
          parentId ? `/p/${parentId}/${response.id}` : `/p/${response.id}`,
        );
      }
    });

    return (
      <Modal
        onClose={() => setMode('closed')}
        opened={mode !== 'closed'}
        title={<Text fw={700}>New Process</Text>}
      >
        <form
          noValidate
          onSubmit={submitHandler}
        >
          <TextInput
            data-autofocus
            error={errors?.title?.message?.toString()}
            label="Title"
            tabIndex={0}
            {...register('title', {
              required: 'Please provide a title',
            })}
          />

          <Space h="md" />

          {!parentId && (
            <>
              <Switch
                label="Can have sub-processes"
                {...register('is_parent')}
              />
              <Divider my="lg" />
            </>
          )}

          <Group>
            <Button type="submit">Create</Button>
            <Button
              onClick={() => {
                setMode('closed');
              }}
              variant="outline"
            >
              Cancel
            </Button>
          </Group>
        </form>
      </Modal>
    );
  },
);

export default Object.assign(ProcessPropsDialog, {
  useRef: () => React.useRef<ProcessPropsRefApi>(null),
});
