import css from './components/Notification.module.css';
import { createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import React from 'react';

const theme = createTheme({
  components: {
    Drawer: {
      defaultProps: {
        overlayProps: {
          blur: 1.5,
          color: '#012',
          opacity: 0.4,
        },
      },
    },
    Modal: {
      defaultProps: {
        overlayProps: {
          blur: 1.5,
          color: '#012',
          opacity: 0.4,
        },
      },
      // styles: () => ({
      //   modal: {
      //     boxShadow: '0 0 6px rgba(0, 0, 0, .3)',
      //   },
      // }),
    },
    Notifications: {
      classNames: css,
    },
  },
});

const withMantine =
  <T extends JSX.IntrinsicAttributes>(
    Component: React.FC<T>,
  ): typeof Component =>
  (props) => {
    return (
      <MantineProvider theme={theme}>
        <Notifications
          containerWidth={280}
          position="top-center"
          style={{
            marginBottom: '60px',
          }}
        />
        <ModalsProvider>
          <Component {...props} />
        </ModalsProvider>{' '}
      </MantineProvider>
    );
  };

export default withMantine;
