import { useProcesses } from '../hooks/useProcesses';
import { ProcessItem } from './ProcessItem';
import { Box, Center, Loader } from '@mantine/core';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type ProcessListProps = {
  parentId?: null | string | undefined;
  visualMode?: 'default' | 'snoozed';
};

const ProcessList = ({
  parentId,
  visualMode: mode = 'default',
}: ProcessListProps) => {
  const { isLoading, processes } = useProcesses({
    filter: { isParent: 'all', isSnoozed: mode === 'snoozed' },
    parentId,
  });
  const navigate = useNavigate();

  return useMemo(() => {
    switch (true) {
      case isLoading:
        return (
          <Center className="pt-[100px]">
            <Loader />
          </Center>
        );
      case processes && processes.length > 0:
        return (
          <Box
          // style={
          //   mode === 'default'
          //     ? {
          //         borderTop: '1px solid rgba(0, 10, 60, .15)',
          //         borderBottom: '1px solid rgba(0, 10, 60, .15)',
          //       }
          //     : {}
          // }
          >
            {processes &&
              processes.map((process, index) => {
                return (
                  <ProcessItem
                    idx={index}
                    key={process.id}
                    onClick={() => {
                      return navigate(
                        parentId
                          ? `/p/${parentId}/${process.id}`
                          : `/p/${process.id}`,
                      );
                    }}
                    process={process}
                  />
                );
              })}
          </Box>
        );
      default:
        return (
          <Center style={{ padding: '50px 0' }}>
            {mode === 'snoozed'
              ? 'Nothing to show'
              : 'There are no processes here yet. Please add some.'}
          </Center>
        );
    }
  }, [isLoading, mode, navigate, parentId, processes]);
};

export default ProcessList;
