/* eslint-disable import/no-unassigned-import */
import App from './App';
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

const MaintenanceMode = () => (
  <div style={{ padding: '5em 0', textAlign: 'center' }}>
    Clew.app is currently in maintenance mode. Please check back later.
    <br /> We apologize for the inconvenience.
  </div>
);

const container = document.querySelector('#root');
if (container) {
  const root = createRoot(container);

  // to prevent tree shaking
  const app = <App />;

  // root.render(__maintenanceMode__ ? <MaintenanceMode /> : app);
  root.render(
    import.meta.env.VITE_MAINTENANCE_MODE ? <MaintenanceMode /> : app,
  );
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js');
}
