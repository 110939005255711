/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Space, TextInput } from '@mantine/core';
import React from 'react';
import { useForm } from 'react-hook-form';

const TaskForm: React.FC<{
  submitHandler: (data: Record<string, any>) => Promise<void>;
}> = ({ submitHandler }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    await submitHandler(data);
    reset();
  });

  return (
    <form
      noValidate
      onSubmit={onSubmit}
    >
      <TextInput
        tabIndex={0}
        {...register('task', {
          required: 'please provide a task description',
        })}
        error={errors?.task?.message?.toString()}
        placeholder="To do"
      />
      <Space h="sm" />
      <Button type="submit">Create a task</Button>
    </form>
  );
};

export default TaskForm;
