import { isAwayAtom } from './atoms/isAway';
import { useInvalidateAll } from './hooks/useInvalidateProcesses';
import useIsAwayManager from './hooks/useIsAwayManager';
import useUser from './hooks/useUser';
import { debug } from './services/debug';
import { SupabaseContext } from './supabase';
import { useAtom } from 'jotai';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';

const withCustomLogic =
  <T extends JSX.IntrinsicAttributes>(
    Component: React.FC<T>,
  ): typeof Component =>
  (props) => {
    const [isAway] = useAtom(isAwayAtom);
    useIsAwayManager();
    const invalidateAll = useInvalidateAll();
    const { refetch, user } = useUser();
    const context = React.useContext(SupabaseContext);

    useEffect(() => {
      context?.supabase.auth.onAuthStateChange((event) => {
        debug('auth state changed', event);
        refetch();
      });
    }, [context?.supabase.auth, refetch]);

    useEffect(() => {
      refetch();
    }, [isAway, refetch]);

    useEffect(() => {
      if (user && !isAway) {
        invalidateAll();
      }
    }, [invalidateAll, isAway, user]);

    useEffect(() => {
      if (user?.email) {
        LogRocket.identify(user.email);
      }
    }, [user]);

    return <Component {...props} />;
  };

export default withCustomLogic;
