import Logo from './Logo';
import { Paper } from '@mantine/core';
import React from 'react';
import { Helmet } from 'react-helmet';

export const LayoutUnauthenticated: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <>
    <Helmet htmlAttributes={{ class: 'unauthenticated' }} />
    <Logo />
    <Paper
      className="p-12 absolute w-[30rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-md:w-[95%] max-md:p-4"
      shadow="md"
    >
      {children}
    </Paper>
  </>
);
