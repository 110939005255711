import { useProcesses } from '../hooks/useProcesses';
import { useProcessMove } from '../hooks/useProcessMove';
import { type Database } from '../supabase/schema';
import * as icons from './Icons';
import { Button, Group, Modal, Radio, Space, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, {
  type MutableRefObject,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Process = Database['public']['Views']['processes_agg']['Row'];

type MoveDialogApi = {
  open: (process: Partial<Process>, onAfterMove?: () => void) => void;
};

const MoveDialog = React.forwardRef<MoveDialogApi>((props, ref) => {
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const { processes: parentProcesses } = useProcesses({
    filter: { isParent: true },
    parentId: null,
  });
  const [process, setProcess] = React.useState<Partial<Process>>();
  const { control, handleSubmit, reset } = useForm();
  const { moveProcess } = useProcessMove();
  const onAfterMoveHandler: MutableRefObject<
    Parameters<MoveDialogApi['open']>[1] | undefined
  > = useRef();

  const dialogOpenHandler: MoveDialogApi['open'] = (proc, onAfterMove) => {
    setProcess(proc);
    reset();
    onAfterMoveHandler.current = onAfterMove;
    setIsDialogVisible(true);
  };

  const cancelHandler = () => setIsDialogVisible(false);

  useImperativeHandle(ref, () => ({
    open: dialogOpenHandler,
  }));

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    if (process && process.id) {
      const response = await moveProcess({
        newParentId: data.destination || null,
        processId: process.id,
      });
      setIsDialogVisible(false);
      if (response.error) {
        showNotification({
          ...icons.failure,
          message: response.error,
        });
      } else {
        navigate(
          data.destination
            ? `/p/${data.destination}/${process.id}`
            : `/p/${process.id}`,
          { replace: true },
        );
        showNotification({
          ...icons.success,
          message: 'Process moved',
        });
      }
    }
  });

  const destinations = useMemo(
    () =>
      [{ id: null, title: '[Top level]' }, ...(parentProcesses ?? [])].filter(
        (destination) => destination.id !== process?.parent_id,
      ),
    [parentProcesses, process?.parent_id],
  );

  let moveDialogContent = (
    <>
      <Text>
        There are no other processes that can have sub-processes. Please create
        one first.
      </Text>
      <Space h="md" />
      <Button onClick={cancelHandler}>Close</Button>
    </>
  );

  if (destinations.length > 0) {
    moveDialogContent = (
      <>
        <Text>
          You are about to move the process &quot;{process?.title}&quot;. Pick
          the destination:
        </Text>

        <form
          noValidate
          onSubmit={onSubmit}
        >
          <Controller
            control={control}
            defaultValue={destinations?.[0]?.id}
            name="destination"
            render={({ field }) => (
              <Radio.Group {...field}>
                {destinations.map((destination) => (
                  <Radio
                    key={destination.id}
                    label={destination.title}
                    style={{ display: 'block', margin: '1rem 0' }}
                    value={destination.id || ''}
                  />
                ))}
              </Radio.Group>
            )}
          />
          <Space h="md" />
          <Group>
            <Button type="submit">Move</Button>
            <Button
              onClick={cancelHandler}
              variant="subtle"
            >
              Cancel
            </Button>
          </Group>
        </form>
      </>
    );
  }

  return (
    <Modal
      onClose={cancelHandler}
      opened={isDialogVisible}
      title={<Text fw={700}>Move process</Text>}
    >
      {moveDialogContent}
    </Modal>
  );
});

export default Object.assign(MoveDialog, {
  useRef: () => React.useRef<MoveDialogApi>(null),
});
