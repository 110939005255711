import { SupabaseContext } from '../supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

type AtomicResult = {
  error: null | string;
  message?: string;
};

export const useAtomicProcessUpdate = (
  onSuccess: (data: AtomicResult) => void,
) => {
  const context = useContext(SupabaseContext);
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: async (
      parameters:
        | {
            action: 'delete' | 'pin' | 'unpin' | 'unsnooze';
            processId: null | string | undefined;
          }
        | {
            action: 'snooze';
            processId: null | string | undefined;
            snoozeUntil: 'infinity' | Date;
          },
    ): Promise<AtomicResult> => {
      const { action, processId } = parameters;
      if (context && processId) {
        const database = context.supabase.from('processes');
        let response;
        let successMeassage;
        switch (action) {
          case 'delete':
            response = await database.delete().match({ id: processId });
            successMeassage = 'Process deleted';
            break;
          case 'pin':
            response = await database
              .update({ is_pinned: true })
              .match({ id: processId });
            successMeassage = 'Process pinned';
            break;
          case 'snooze':
            response = await database
              .update({
                snoozed_until:
                  typeof parameters.snoozeUntil === 'string'
                    ? parameters.snoozeUntil
                    : parameters.snoozeUntil.toISOString(),
              })
              .match({ id: processId });
            successMeassage = 'Process snoozed';
            break;
          case 'unpin':
            response = await database
              .update({ is_pinned: false })
              .match({ id: processId });
            successMeassage = 'Process unpinned';
            break;
          case 'unsnooze':
            response = await database
              .update({ snoozed_until: null })
              .match({ id: processId });
            successMeassage = 'Process unsnoozed';
            break;
        }

        if (!response || response.error) {
          return {
            error:
              response?.error?.message || 'Unexpected error, try again later',
          };
        }

        queryClient.invalidateQueries({ queryKey: ['processes'] });
        queryClient.invalidateQueries({ queryKey: ['process'] });
        return {
          error: null,
          message: successMeassage,
        };
      }

      return {
        error: 'Unexpected error, try again later',
      };
    },
    onSuccess,
  });

  return { performAtomicUpdate: mutateAsync };
};
