import { useAtomicProcessUpdate } from '../hooks/useAtomicProcessUpdate';
import { useProcesses } from '../hooks/useProcesses';
import { type Database } from '../supabase/schema';
import * as icons from './Icons';
import {
  Anchor,
  Button,
  Combobox,
  Divider,
  Group,
  Input,
  InputBase,
  Loader,
  Modal,
  Space,
  Stack,
  Text,
  useCombobox,
  useMantineTheme,
} from '@mantine/core';
import { type DateValue } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import dayjs, { type ManipulateType } from 'dayjs';
import React, {
  type MutableRefObject,
  Suspense,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type Process = Database['public']['Views']['processes_agg']['Row'];

const DatePicker = React.lazy(() =>
  // eslint-disable-next-line promise/prefer-await-to-then
  import('./DatePicker').then((module) => ({ default: module.DatePicker })),
);

type SnoozeDialogApi = {
  open: (process: Partial<Process>, onAfterSnooze?: () => void) => void;
};

const shortcuts: Array<{ label: string; value: [number, ManipulateType] }> = [
  { label: '1 day', value: [1, 'day'] },
  { label: '3 days', value: [3, 'day'] },
  { label: '1 week', value: [1, 'week'] },
  { label: '2 weeks', value: [2, 'week'] },
  { label: '1 month', value: [1, 'month'] },
  { label: '3 months', value: [3, 'month'] },
  { label: '6 months', value: [6, 'month'] },
  { label: '1 year', value: [1, 'year'] },
];

const SnoozeDialog = React.forwardRef<SnoozeDialogApi>((props, ref) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [process, setProcess] = useState<Partial<Process>>();
  const [date, setDate] = useState<DateValue>(null);
  const { invalidate } = useProcesses({ parentId: process?.parent_id });
  const onAfterSnoozeHandler: MutableRefObject<
    Parameters<SnoozeDialogApi['open']>[1] | undefined
  > = useRef();

  const { performAtomicUpdate } = useAtomicProcessUpdate((data) => {
    if (data?.error) {
      showNotification({
        ...icons.failure,
        message: data.error,
      });
    } else {
      showNotification({
        ...icons.success,
        message: data?.message,
      });
      invalidate();
      setIsDialogVisible(false);
    }
  });

  const theme = useMantineTheme();

  const dialogOpenHandler: SnoozeDialogApi['open'] = (
    process_,
    onAfterSnooze,
  ) => {
    setProcess(process_);
    setDate(null);
    onAfterSnoozeHandler.current = onAfterSnooze;
    setIsDialogVisible(true);
  };

  const cancelHandler = () => setIsDialogVisible(false);

  useImperativeHandle(ref, () => ({
    open: dialogOpenHandler,
  }));

  const applySnooze = async () => {
    if (!date) return;
    await performAtomicUpdate({
      action: 'snooze',
      processId: process?.id,
      snoozeUntil: date,
    });
    onAfterSnoozeHandler.current?.();
  };

  const snoozeForver = async () => {
    await performAtomicUpdate({
      action: 'snooze',
      processId: process?.id,
      snoozeUntil: 'infinity',
    });
    onAfterSnoozeHandler.current?.();
  };

  const isSmallScreen = useMediaQuery('(max-width: 490px)');

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  return (
    <Modal
      onClose={cancelHandler}
      opened={isDialogVisible}
      title={<Text fw={700}>Snooze process</Text>}
    >
      <Text>Snooze the process &quot;{process?.title}&quot;</Text>
      <Space h="lg" />
      <Group
        align="center"
        justify="flex-start"
        wrap="nowrap"
      >
        {!isSmallScreen && (
          <>
            <Stack gap="xs">
              {shortcuts.map((shortcut) => (
                <Button
                  key={shortcut.label}
                  onClick={() =>
                    setDate(
                      dayjs()
                        .add(...shortcut.value)
                        .toDate(),
                    )
                  }
                  variant="outline"
                >
                  {shortcut.label}
                </Button>
              ))}
            </Stack>
            <Divider orientation="vertical" />
          </>
        )}
        <div className="flex flex-col items-end grow">
          {isSmallScreen && (
            <Combobox
              onOptionSubmit={(value) => {
                setDate(
                  dayjs()
                    .add(...shortcuts[Number.parseInt(value, 10)].value)
                    .toDate(),
                );
                combobox.closeDropdown();
              }}
              store={combobox}
            >
              <Combobox.Target>
                <InputBase
                  className="self-start"
                  component="button"
                  onClick={() => combobox.toggleDropdown()}
                  pointer
                  rightSection={<Combobox.Chevron />}
                  rightSectionPointerEvents="none"
                  type="button"
                >
                  <Input.Placeholder>Choose a preset</Input.Placeholder>
                </InputBase>
              </Combobox.Target>
              <Combobox.Dropdown>
                {shortcuts.map((item, index) => (
                  <Combobox.Option
                    key={item.label}
                    value={`${index}`}
                  >
                    {item.label}
                  </Combobox.Option>
                ))}
              </Combobox.Dropdown>
            </Combobox>
          )}
          <Suspense fallback={<Loader />}>
            <DatePicker
              className="self-center my-5"
              date={date || undefined}
              excludeDate={(date_: Date) => date_ < new Date()}
              firstDayOfWeek={0}
              getDayProps={(date_: Date) =>
                dayjs(date_).isSame(dayjs(), 'day')
                  ? {
                      style: {
                        backgroundColor: theme.colors.blue[1],
                        color: theme.colors.gray[6],
                      },
                    }
                  : {}
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={setDate as any}
              type="default"
              value={date}
            />
            <Button
              disabled={!date}
              onClick={applySnooze}
              style={{ margin: '5px 0' }}
            >
              Snooze
            </Button>
            <Anchor
              component="button"
              onClick={snoozeForver}
              size="sm"
              type="button"
            >
              snooze forever
            </Anchor>
          </Suspense>
        </div>
      </Group>
    </Modal>
  );
});

export default Object.assign(SnoozeDialog, {
  useRef: () => React.useRef<SnoozeDialogApi>(null),
});
