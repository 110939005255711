import logo from '../logo-black.png';
import { Image } from '@mantine/core';
import React from 'react';

const Logo: React.FC = () => {
  return (
    <div
      style={{
        fontSize: '30px',
        fontWeight: 'bold',
        height: '120px',
        lineHeight: '120px',
        textAlign: 'center',
      }}
    >
      <Image
        src={logo}
        style={{
          display: 'inline-block',
          margin: '0 10px 0 0',
          verticalAlign: 'middle',
          width: '200px',
        }}
      />
    </div>
  );
};

export default Logo;
