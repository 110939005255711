import useUser from '../hooks/useUser';
import { SupabaseContext } from '../supabase';
import {
  Alert,
  Button,
  Popover,
  Space,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { IoChatboxEllipsesOutline, IoCheckmarkSharp } from 'react-icons/io5';

const FeedbackPopup = () => {
  const { user } = useUser();
  const context = useContext(SupabaseContext);

  const {
    formState: { isSubmitted, isSubmitting },
    handleSubmit,
    register,
  } = useForm();
  const onSubmit = handleSubmit(async (initialData) => {
    const data = initialData;
    data['Logged in as'] = user?.email;
    data.Browser = window.navigator.userAgent;
    data.Platform = window.navigator.platform;
    return context?.supabase.functions.invoke('clew-server/send-feedback', {
      body: data,
    });
  });

  return (
    <Popover
      closeOnClickOutside
      position="bottom"
      shadow="md"
      width={350}
      withArrow
      withinPortal
    >
      <Popover.Target>
        <Button
          color="dark"
          leftSection={<IoChatboxEllipsesOutline />}
          size="compact-sm"
          style={{ verticalAlign: 'middle' }}
          variant="subtle"
        >
          Feedback
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        {isSubmitted ? (
          <Alert
            color="green"
            icon={<IoCheckmarkSharp />}
          >
            Thanks for your feedback!
          </Alert>
        ) : (
          <>
            <Text
              size="sm"
              style={{ marginBottom: '10px' }}
            >
              We would love to hear from you. Please share your thoughts using
              the form below.
            </Text>
            <form
              noValidate
              onSubmit={onSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                textAlign: 'left',
              }}
            >
              <TextInput
                defaultValue={user?.email}
                label="Email address"
                {...register('Email', {
                  pattern: {
                    message: 'Entered value does not match email format',
                    value: /\S[^\s@]*@\S+\.\S+/u,
                  },
                  required: 'This field is required',
                })}
              />
              <TextInput
                label="Name"
                {...register('Name', { required: 'This field is required' })}
              />
              <Textarea
                label="Message"
                {...register('Message', { required: 'This field is required' })}
              />
              <Space h="sm" />
              <Button
                loading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

export const Feedback = () => <FeedbackPopup key={Math.random()} />;
