import { AddProcessButton } from '../components/AddProcessButton';
import Layout from '../components/Layout';
import ProcessList from '../components/ProcessList';
import { Snoozed } from '../components/Snoozed';
import withAuth from '../withAuth';
import { Box, Container } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const Dashboard: React.FC = withAuth(() => {
  return (
    <Layout headerRight={<AddProcessButton />}>
      <Container size="md">
        <Box style={{ paddingLeft: '25px' }}>
          <ProcessList />
          <Outlet />
        </Box>
        <Snoozed />
      </Container>
    </Layout>
  );
});
