import React from 'react';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';

export const success = {
  icon: (
    <IoCheckmarkCircleOutline
      aria-label="Success"
      color="#76f78d"
      role="img"
      size={24}
    />
  ),
};
export const failure = {
  icon: (
    <IoCloseCircleOutline
      aria-label="Failure"
      color="#ff9b74"
      role="img"
      size={24}
    />
  ),
};
