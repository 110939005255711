import { isAwayAtom } from '../atoms/isAway';
import { debug } from '../services/debug';
import { useIdle } from '@mantine/hooks';
import { useAtom } from 'jotai';
import React from 'react';

const TIMEOUT_TIME = 5 * 60 * 1_000;

const useIsAwayManager = () => {
  const [isAway, setIsAway] = useAtom(isAwayAtom);

  const idle = useIdle(TIMEOUT_TIME, { initialState: false });
  React.useEffect(() => {
    setIsAway((value) => (value === idle ? value : idle));
  }, [idle, setIsAway]);

  React.useEffect(() => {
    debug(isAway ? 'User is away' : 'User is back');
  }, [isAway]);

  React.useEffect(() => {
    for (const type of ['focus', 'blur', 'visibilitychange']) {
      window.addEventListener(
        type,
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        (event) => {
          if (event.target !== window) {
            return;
          }

          // console.log(e);
          setIsAway((value) => {
            const blurOrHidden =
              event.type === 'blur' || document.visibilityState === 'hidden';
            return value === blurOrHidden ? value : blurOrHidden;
          });
        },
        {
          capture: true,
        },
      );
    }
  }, [setIsAway]);
};

export default useIsAwayManager;
