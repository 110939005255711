import ProcessList from './ProcessList';
import { Anchor, Collapse, Space } from '@mantine/core';
import React, { useState } from 'react';
import { IoCaretDown, IoCaretForward } from 'react-icons/io5';

export const Snoozed = ({
  parentId,
}: {
  parentId?: null | string | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const caret = isOpen ? (
    <IoCaretDown className="inline-block" />
  ) : (
    <IoCaretForward className="inline-block" />
  );
  return (
    <div>
      <Space h="lg" />
      {caret}
      <Anchor
        className="ml-1 align-baseline"
        onClick={() => setIsOpen((value) => !value)}
        variant="text"
      >
        Snoozed processes
      </Anchor>
      <Space h="sm" />
      <Collapse
        in={isOpen}
        style={{
          backgroundColor: 'rgb(233 230 213)',
          marginLeft: '10px',
          marginRight: '-15px',
          padding: '0 15px',
        }}
      >
        {isOpen && (
          <ProcessList
            parentId={parentId}
            visualMode="snoozed"
          />
        )}
      </Collapse>
    </div>
  );
};
