/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContextCreate } from '../../hooks/useContextCreate';
import { useContextUpdate } from '../../hooks/useContextUpdate';
import { useProcess } from '../../hooks/useProcess';
import { debug } from '../../services/debug';
import { isProcessSnoozed } from '../../services/isProcessSnoozed';
import { type Database } from '../../supabase/schema';
import CommentForm from './CommentForm';
import contextCss from './Context.module.css';
import tabsCss from './Tabs.module.css';
import TaskForm from './TaskForm';
import { Box, Checkbox, ScrollArea, Space, Tabs, Text } from '@mantine/core';
import { Autolinker } from 'autolinker';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { IoReaderOutline } from 'react-icons/io5';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

type Context = Database['public']['Tables']['context_records']['Insert'];
type ProgressProps = {
  context?: Context[] | null;
  disabled?: boolean;
  processId: null | string;
  readOnly?: boolean;
};

const encodeHtmlEntities = (string_: string) =>
  String(string_)
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;');

const Progress = ({
  context,
  disabled,
  processId,
  readOnly = false,
}: ProgressProps) => {
  const { process, reloadProcess } = useProcess(processId);

  const isSnoozed = useMemo(() => isProcessSnoozed(process), [process]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, []);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const { createContextRecord } = useContextCreate();

  const { updateContextRecord } = useContextUpdate();

  const submitHandler = async (data: Record<string, any>) => {
    if (!processId) {
      return;
    }

    const record: Context = {
      [`process_id`]: processId,
      content: data.comment ? data.comment : data.task,
      type: data.comment ? 'COMMENT' : 'INCOMPLETE_TASK',
    };

    debug('creating context record');
    await createContextRecord({
      data: record,
      processParentId: process?.parent_id,
    });
  };

  const toDoChangeHandler =
    (id: string | undefined) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!id) {
        return;
      }

      await updateContextRecord({
        data: {
          [`created_at`]: new Date().toISOString(),
          type: event.target.checked ? 'COMPLETE_TASK' : 'INCOMPLETE_TASK',
        },
        id,
        processParentId: process?.parent_id,
      });

      reloadProcess();
    };

  const tasks: React.ReactNode[] = [];

  return (
    <>
      <ScrollArea
        scrollbarSize={8}
        style={{ flexGrow: 1 }}
        viewportRef={scrollAreaRef}
      >
        {context &&
          context.map((item) => {
            if (item.type === 'INCOMPLETE_TASK') {
              tasks.push(
                <Checkbox
                  disabled={readOnly}
                  key={item.id}
                  label={
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: Autolinker.link(
                          encodeHtmlEntities(item.content || ''),
                        ),
                      }}
                    />
                  }
                  onChange={toDoChangeHandler(item.id)}
                />,
              );
              return null;
            }

            const createdAt = dayjs(item.created_at);

            const content =
              item.type === 'COMPLETE_TASK' ? (
                <Checkbox
                  checked
                  disabled={dayjs().isAfter(
                    dayjs(item.updated_at).add(5, 'minute'),
                  )}
                  label={
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: Autolinker.link(
                          encodeHtmlEntities(item.content || ''),
                        ),
                      }}
                    />
                  }
                  onChange={toDoChangeHandler(item.id)}
                />
              ) : (
                <Text
                  className="break-all whitespace-break-spaces overscroll-contain pr-3 [&_a]:text-blue-800"
                  dangerouslySetInnerHTML={{
                    __html: Autolinker.link(
                      encodeHtmlEntities(item.content || ''),
                      {
                        stripPrefix: false,
                      },
                    ),
                  }}
                />
              );

            return (
              <div key={item.id}>
                <Text
                  c="gray"
                  size="sm"
                  style={{ paddingLeft: '20px' }}
                  title={createdAt.format('LLL')}
                >
                  <IoReaderOutline
                    style={{
                      float: 'left',
                      marginLeft: '-20px',
                      marginTop: '3px',
                      opacity: 0.5,
                    }}
                  />
                  {createdAt.fromNow()}
                </Text>
                {content}
                <Space h="sm" />
              </div>
            );
          })}
        {tasks.length > 0 && <Box className={contextCss.tasks}>{tasks}</Box>}
      </ScrollArea>
      {!isSnoozed && !disabled && (
        <Tabs
          classNames={tabsCss}
          defaultValue="comment"
          variant="unstyled"
        >
          <Tabs.List>
            <Tabs.Tab value="comment">Comment</Tabs.Tab>
            <Tabs.Tab value="task">Task</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="comment">
            <CommentForm submitHandler={submitHandler} />
          </Tabs.Panel>
          <Tabs.Panel value="task">
            <TaskForm submitHandler={submitHandler} />
          </Tabs.Panel>
        </Tabs>
      )}
    </>
  );
};

export default Progress;
