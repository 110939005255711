import { drawersAtom } from '../../atoms/drawers';
import { useAtomicProcessUpdate } from '../../hooks/useAtomicProcessUpdate';
import { useProcess } from '../../hooks/useProcess';
import { useProcesses } from '../../hooks/useProcesses';
import { useProcessUpdate } from '../../hooks/useProcessUpdate';
import { isProcessSnoozed } from '../../services/isProcessSnoozed';
import { AddProcessButton } from '../AddProcessButton';
import DangerConfirmationDialog from '../DangerConfirmationDialog';
import * as icons from '../Icons';
import { InlineEditable } from '../InlineEditable';
import MoveDialog from '../MoveDialog';
import ProcessList from '../ProcessList';
import Progress from '../Progress';
import { Snoozed } from '../Snoozed';
import SnoozeDialog from '../SnoozeDialog';
import Menu from './Menu';
import {
  ActionIcon,
  Box,
  CloseIcon,
  Drawer,
  Loader,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

type ProcessDetailsProps = {
  level: 1 | 2;
};

export const ProcessDetails = ({ level }: ProcessDetailsProps) => {
  const { id1, id2 } = useParams<{ id1: string; id2: string }>();
  const processId = level === 1 ? id1 : id2;
  const { context, process, reloadProcess } = useProcess(processId);
  const { invalidate } = useProcesses({ parentId: process?.parent_id });
  const [titleStatus, setTitleStatus] = useState<'default' | 'editing'>(
    'default',
  );
  const [drawersRegistry, setDrawersRegistry] = useAtom(drawersAtom);
  const navigate = useNavigate();
  const readOnly = useMemo(() => {
    return isProcessSnoozed(process);
  }, [process]);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (processId) {
      setDrawersRegistry((previous) => [...previous, processId]);
      setOpened(true);
      return () => {
        setDrawersRegistry((previous) =>
          previous.filter((item) => item !== processId),
        );
      };
    }

    return undefined;
  }, [processId, setDrawersRegistry]);

  const deleteDialogRef = DangerConfirmationDialog.useRef();
  const moveDialogRef = MoveDialog.useRef();
  const snoozeDialogRef = SnoozeDialog.useRef();

  const { performAtomicUpdate } = useAtomicProcessUpdate((data) => {
    if (data?.error) {
      showNotification({
        ...icons.failure,
        message: data.error,
      });
    } else {
      showNotification({
        ...icons.success,
        message: data?.message,
      });
      invalidate();
    }
  });

  const { updateProcess } = useProcessUpdate();

  const updateTitle = async (title: string) => {
    const response = await updateProcess({ data: { title }, processId });
    if (!response?.error) {
      invalidate();
      reloadProcess();
    }
  };

  let content;

  const drawerSize = process?.parent_id ? '90%' : '95%';

  const isSnoozed = useMemo(() => isProcessSnoozed(process), [process]);

  if (process) {
    const pinHandler = process.is_pinned
      ? undefined
      : () => {
          performAtomicUpdate({
            action: 'pin',
            processId,
          });
          setOpened(false);
        };

    const unpinHandler = process.is_pinned
      ? () => {
          performAtomicUpdate({
            action: 'unpin',
            processId,
          });
          setOpened(false);
        }
      : undefined;

    const moveHandler = process.is_parent
      ? undefined
      : () => {
          moveDialogRef.current?.open(process);
        };

    const menu = isSnoozed ? (
      <Menu
        deleteHandler={() => {
          deleteDialogRef.current?.open();
        }}
        unsnoozeHandler={async () => {
          await performAtomicUpdate({
            action: 'unsnooze',
            processId,
          });
          setOpened(false);
        }}
      />
    ) : (
      <Menu
        deleteHandler={() => {
          deleteDialogRef.current?.open();
        }}
        moveHandler={moveHandler}
        pinHandler={pinHandler}
        snoozeHandler={() => {
          snoozeDialogRef.current?.open(process, () => setOpened(false));
        }}
        unpinHandler={unpinHandler}
      />
    );

    const listOrProgress = process.is_parent ? (
      <>
        <Box className="pl-6">
          <ProcessList parentId={process.id} />
        </Box>
        <Snoozed parentId={process.id} />
      </>
    ) : (
      <Progress
        context={context}
        processId={process.id}
        readOnly={readOnly}
      />
    );

    content = (
      <>
        <div className="flex flex-row gap-3 mr-0 justify-between items-center">
          {process.is_pinned && (
            <BsPinAngleFill
              className="mr-3 self-center"
              color="#ff8300"
            />
          )}
          <InlineEditable
            defaultValue={process?.title || ''}
            disabled={isSnoozed || readOnly}
            onChange={updateTitle}
            onStatusChange={setTitleStatus}
            renderDefault={(value) => <Title order={3}>{value}</Title>}
            style={{ flexGrow: 1 }}
          />
          {titleStatus === 'default' && (
            <>
              {process && process.is_parent && !readOnly && (
                <AddProcessButton parentId={process.id} />
              )}
              {menu}
            </>
          )}
          <ActionIcon
            color="gray"
            onClick={() => setOpened(false)}
            tabIndex={-1}
            variant="subtle"
          >
            {/* <IoCloseOutline size={20} /> */}
            <CloseIcon />
          </ActionIcon>
        </div>
        {listOrProgress}
      </>
    );
  } else {
    content = (
      <Loader
        color="gray"
        style={{ alignSelf: 'end' }}
        variant="dots"
      />
    );
  }

  return (
    <>
      <Drawer
        closeOnEscape={
          drawersRegistry[drawersRegistry.length - 1] === processId
        }
        lockScroll
        onClose={() => setOpened(false)}
        opened={opened}
        padding="md"
        position="bottom"
        size={drawerSize}
        styles={{
          body: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            height: '100%',
            justifyContent: 'flex-start',
          },
          content: {
            backgroundColor: process?.is_parent ? '#f0f0f0' : '#fff',
            borderRadius: '8px 8px 0 0',
            boxShadow: '0 -2px 6px rgba(0, 0, 0, .2)',
          },
          inner: {
            left: '50%',
            maxWidth: '960px',
            transform: 'translateX(-50%)',
            zIndex: 'auto',
          },
          overlay: {
            zIndex: 'auto',
          },
        }}
        transitionProps={{
          onExited: () => navigate(level === 1 ? '/' : `/p/${id1}`),
        }}
        withCloseButton={false}
        withinPortal
      >
        {content}
      </Drawer>
      <DangerConfirmationDialog
        commitDangerousActionHandler={async () => {
          await performAtomicUpdate({ action: 'delete', processId });
          setOpened(false);
        }}
        ref={deleteDialogRef}
        title="Delete Process"
      />
      <MoveDialog ref={moveDialogRef} />
      <SnoozeDialog ref={snoozeDialogRef} />
      <Outlet />
    </>
  );
};
