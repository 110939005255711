import { key } from './useProcesses';
import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateProcesses = () => {
  const queryClient = useQueryClient();

  return (parentId: null | string | undefined) => {
    queryClient.invalidateQueries({ queryKey: [key(parentId)] });
    if (parentId) {
      queryClient.invalidateQueries({ queryKey: [key(null)] });
    }
  };
};

export const useInvalidateAll = () => {
  const queryClient = useQueryClient();
  return () => {
    const cache = queryClient.getQueryCache();
    for (const query of cache.findAll()) {
      if (`${query.queryKey[0]}`.startsWith('process-list')) {
        queryClient.invalidateQueries({ queryKey: query.queryKey });
      }
    }
  };
};
