import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import { SupabaseContext } from '../supabase';
import withAuth from '../withAuth';
import { Button, Container, Space, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

export const Invite = withAuth(() => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const context = useContext(SupabaseContext);
  const submitHandler = handleSubmit(async (data) => {
    const response = await context?.supabase.functions.invoke(
      'clew-server/send-invite',
      {
        body: data,
      },
    );

    if (!response || response.data.error) {
      setError('email', {
        message: response
          ? response.data.error.message
          : 'Something went wrong. Try again later',
      });
    } else {
      showNotification({ message: 'Invite sent successfully' });
      reset();
    }
  });

  return (
    <Layout>
      <Container size="md">
        <Breadcrumbs items={[{ '/': 'Home' }, 'Send Invite']} />
        <Container
          size="xs"
          style={{ padding: 40 }}
        >
          <form
            noValidate
            onSubmit={submitHandler}
          >
            <TextInput
              error={errors?.email?.message?.toString()}
              label="Email address"
              type="email"
              {...register('email', {
                pattern: {
                  message: 'Entered value does not match email format',
                  value: /\S[^\s@]*@\S+\.\S+/u,
                },
                required: 'This field is required',
              })}
            />
            <Space h="md" />
            <Button
              loading={isSubmitting}
              type="submit"
            >
              Send
            </Button>
          </form>
        </Container>
      </Container>
    </Layout>
  );
});
