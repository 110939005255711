import useUser from '../hooks/useUser';
import logo from '../logo-black.png';
import * as routes from '../routes';
import { SupabaseContext } from '../supabase';
import { AirbrakeContext } from '../withAirbrake';
import { Feedback } from './Feedback';
import * as icons from './Icons';
import { Container, Image, Menu, Text, UnstyledButton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import LogRocket from 'logrocket';
import React, { useContext } from 'react';
import { IoCaretDown, IoPerson } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Layout: React.FC<{
  children: React.ReactNode | React.ReactNode[];
  headerRight?: React.ReactNode;
}> = ({ children, headerRight = null }) => {
  const context = useContext(SupabaseContext);
  const airbrake = useContext(AirbrakeContext);
  const { user } = useUser();

  const headerRightJSX = headerRight ? <div>{headerRight}</div> : null;

  return (
    <>
      <div className="mb-5 bg-sky-100 shadow-[0_0_10px_rgba(0,0,0,0.1),0_0_5px_rgba(0,0,0,0.15)]">
        <Container size="md">
          <div className="flex leading-[60px] align-middle">
            <div className="flex-1 font-bold">
              <Image
                className="w-[120px] inline-block"
                src={logo}
              />
            </div>

            {headerRightJSX}
            <Menu>
              <Menu.Target>
                <UnstyledButton className="leading-[50px] py-0 pr-[20px] pl-[10px]">
                  <IoPerson
                    className="inline-block"
                    size={20}
                  />
                  <IoCaretDown className="float-right m-0 mt-[17px] -mr-[20px]" />
                </UnstyledButton>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{user?.email}</Menu.Label>
                <Menu.Item
                  component={Link}
                  to={routes.resetPassword}
                >
                  <Text>Reset password</Text>
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  to={routes.invite}
                >
                  <Text>Send Invite</Text>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  onClick={async () => {
                    if (context) {
                      const { error } = await context.supabase.auth.signOut();
                      if (error) {
                        LogRocket.captureMessage(error.message);
                        airbrake?.notify(error);
                        showNotification({
                          ...icons.failure,
                          message:
                            'There was an error signing out. Please try again later.',
                        });
                      } else {
                        document.location.assign(routes.signIn);
                      }
                    }
                  }}
                >
                  <Text>Sign out</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Container>
      </div>
      {children}
      <div className="absolute bottom-0 left-0 right-0 h-[60px] bg-zinc-300 leading-[60px] text-center text-sm text-zinc-600 shadow-[0_6px_6px_rgba(0,0,0,0.1)_inset]">
        &copy; {dayjs().format('YYYY')} Clew. Version
        {dayjs(__buildDate__).format(' YYYY-MM-DD ')}
        <Feedback />
      </div>
    </>
  );
};

export default Layout;
