import { PROCESSES_TABLE_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { type Database } from '../supabase/schema';
import { useInvalidateProcesses } from './useInvalidateProcesses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

type Process = Database['public']['Tables']['processes']['Row'];

export const useProcessUpdate = () => {
  const context = useContext(SupabaseContext);
  const queryClient = useQueryClient();

  const invalidateProcesses = useInvalidateProcesses();

  const { mutateAsync } = useMutation({
    mutationFn: async (parameters: {
      data: Partial<Process>;
      processId: null | string | undefined;
    }) => {
      const { data, processId } = parameters;
      let response;

      if (context && processId) {
        response = await context?.supabase
          .from(PROCESSES_TABLE_NAME)
          .update(data)
          .match({ id: processId });
        if (response && !response.error) {
          invalidateProcesses(data.parent_id);
          queryClient.invalidateQueries({ queryKey: ['process'] });
          return {
            error: null,
          };
        }
      }

      return {
        error: response?.error.message || 'Unknown error',
      };
    },
  });

  return { updateProcess: mutateAsync };
};
