import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

const queryClient = new QueryClient();

const withReactQueryProvider =
  <T extends JSX.IntrinsicAttributes>(
    Component: React.FC<T>,
  ): typeof Component =>
  (props) => (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Component {...props} />
    </QueryClientProvider>
  );

export default withReactQueryProvider;
