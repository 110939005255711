/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Space, Textarea } from '@mantine/core';
import React from 'react';
import { useForm } from 'react-hook-form';

const CommentForm: React.FC<{
  submitHandler: (data: Record<string, any>) => Promise<void>;
}> = ({ submitHandler }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    await submitHandler(data);
    reset();
  });

  return (
    <form
      noValidate
      onSubmit={onSubmit}
    >
      <Textarea
        error={errors?.comment?.message?.toString()}
        tabIndex={0}
        {...register('comment', {
          required: 'please provide a message',
        })}
        placeholder="What's new?"
      />
      <Space h="sm" />
      <Button type="submit">Post an update</Button>
    </form>
  );
};

export default CommentForm;
