import ProcessPropsDialog from './ProcessPropsDialog';
import { Button } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { IoAdd } from 'react-icons/io5';

type AddProcessButtonProps = {
  parentId?: null | string;
};

export const AddProcessButton = ({ parentId }: AddProcessButtonProps) => {
  const processPropsDialogRef = ProcessPropsDialog.useRef();
  const isWideEnough = useMediaQuery('(min-width: 600px)');
  return (
    <>
      <Button
        color="green"
        onClick={() => {
          processPropsDialogRef.current?.openNew(parentId);
        }}
        style={{ marginRight: '10px', verticalAlign: 'middle' }}
      >
        <IoAdd size={24} />
        {isWideEnough && 'Add Process'}
      </Button>
      <ProcessPropsDialog ref={processPropsDialogRef} />
    </>
  );
};
