import useUser from './hooks/useUser';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth =
  <T extends JSX.IntrinsicAttributes>(
    Component: React.FC<T>,
  ): typeof Component =>
  (props) => {
    const navigate = useNavigate();
    const [authOk, setAuthOk] = React.useState(false);
    const { isLoading, user } = useUser();

    React.useEffect(() => {
      if (isLoading) {
        return;
      }

      if (user) {
        setAuthOk(true);
        return;
      }

      navigate('/signin', { replace: true });
    }, [isLoading, navigate, user]);

    return authOk ? <Component {...props} /> : null;
  };

export default withAuth;
