import { PROCESSES_TABLE_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { useInvalidateAll } from './useInvalidateProcesses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

export const useProcessMove = () => {
  const context = useContext(SupabaseContext);
  const queryClient = useQueryClient();

  const invalidateAll = useInvalidateAll();

  const { mutateAsync } = useMutation({
    mutationFn: async (parameters: {
      newParentId: string;
      processId: string;
    }) => {
      const { newParentId, processId } = parameters;
      let response;

      if (context) {
        response = await context?.supabase
          .from(PROCESSES_TABLE_NAME)
          .update({ parent_id: newParentId })
          .match({ id: processId });
        if (response && !response.error) {
          invalidateAll();
          queryClient.invalidateQueries({ queryKey: ['process'] });
          return {
            error: null,
          };
        }
      }

      return {
        error: response?.error.message || 'Unknown error',
      };
    },
  });

  return { moveProcess: mutateAsync };
};
