import { ProcessDetails } from './components/ProcessDetails';
import { Dashboard } from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import { Invite } from './pages/Invite';
import ResetPassword from './pages/ResetPassword';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import * as routes from './routes';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<Signin />}
          path={routes.signIn}
        />
        <Route
          element={<ForgotPassword />}
          path={routes.forgotPassword}
        />
        <Route
          element={<ResetPassword />}
          path={routes.resetPassword}
        />
        <Route
          element={<Invite />}
          path={routes.invite}
        />
        <Route
          element={<Signup />}
          path={routes.signUp}
        />
        <Route
          element={<Dashboard />}
          path="/"
        >
          <Route
            element={<ProcessDetails level={1} />}
            path="p/:id1"
          >
            <Route
              element={<ProcessDetails level={2} />}
              path=":id2"
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
