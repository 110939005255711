import { ActionIcon, Box, Divider, Menu as MenuBase } from '@mantine/core';
import React from 'react';
import { BsPinAngle } from 'react-icons/bs';
import {
  IoAlarmOutline,
  IoArrowRedoOutline,
  IoEllipsisHorizontal,
  IoTimerOutline,
  IoTrashOutline,
} from 'react-icons/io5';

type MenuProps = {
  deleteHandler?: () => void;
  moveHandler?: () => void;
  pinHandler?: () => void;
  snoozeHandler?: () => void;
  unpinHandler?: () => void;
  unsnoozeHandler?: () => void;
};

const Menu = ({
  deleteHandler,
  moveHandler,
  pinHandler,
  snoozeHandler,
  unpinHandler,
  unsnoozeHandler,
}: MenuProps) => {
  return (
    <MenuBase>
      <MenuBase.Target>
        <ActionIcon
          color="grey"
          tabIndex={-1}
          variant="subtle"
        >
          <IoEllipsisHorizontal size={20} />
        </ActionIcon>
      </MenuBase.Target>

      <MenuBase.Dropdown>
        {pinHandler && (
          <MenuBase.Item
            leftSection={<BsPinAngle />}
            onClick={pinHandler}
          >
            Pin
          </MenuBase.Item>
        )}
        {unpinHandler && (
          <MenuBase.Item
            leftSection={
              <>
                <BsPinAngle />
                <Box className="w-[18px] h-[3px] bg-white -mt-[1px] ml-[2px] rotate-[58deg] absolute pt-[1px] after:content-[''] after:block after:bg-black after:h-[1px]" />
              </>
            }
            onClick={unpinHandler}
          >
            Unpin
          </MenuBase.Item>
        )}
        {snoozeHandler && (
          <MenuBase.Item
            color="yellow"
            leftSection={<IoTimerOutline />}
            onClick={snoozeHandler}
          >
            Snooze
          </MenuBase.Item>
        )}
        {unsnoozeHandler && (
          <MenuBase.Item
            leftSection={<IoAlarmOutline />}
            onClick={unsnoozeHandler}
          >
            Unsnooze
          </MenuBase.Item>
        )}
        {moveHandler && (
          <MenuBase.Item
            leftSection={<IoArrowRedoOutline />}
            onClick={moveHandler}
          >
            Move
          </MenuBase.Item>
        )}
        {deleteHandler && <Divider />}
        {deleteHandler && (
          <MenuBase.Item
            color="red"
            leftSection={<IoTrashOutline />}
            onClick={deleteHandler}
          >
            Delete
          </MenuBase.Item>
        )}
      </MenuBase.Dropdown>
    </MenuBase>
  );
};

export default Menu;
