import { CONTEXT_TABLE_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { type Database } from '../supabase/schema';
import { useInvalidateProcesses } from './useInvalidateProcesses';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

type Context = Database['public']['Tables']['context_records']['Update'];

export const useContextUpdate = () => {
  const context = useContext(SupabaseContext);

  const invalidateProcesses = useInvalidateProcesses();

  const { mutateAsync } = useMutation({
    mutationFn: async (parameters: {
      data: Context;
      id: string;
      processParentId?: null | string;
    }) => {
      const { data, id } = parameters;
      let response;

      if (context) {
        response = await context?.supabase
          .from(CONTEXT_TABLE_NAME)
          .update(data)
          .match({ id });
        if (response && !response.error) {
          invalidateProcesses(parameters.processParentId);
          return {
            error: null,
          };
        }
      }

      return {
        error: response?.error?.message || 'Unknown error',
      };
    },
  });

  return { updateContextRecord: mutateAsync };
};
