import { Anchor, Breadcrumbs as BreadcrumbsBase } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbsProps = {
  items: Array<Record<string, string> | string>;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <BreadcrumbsBase>
      {items.map((item) =>
        typeof item === 'object' ? (
          <Anchor
            component={Link}
            key={Object.values(item)[0]}
            to={Object.keys(item)[0]}
          >
            {Object.values(item)[0]}
          </Anchor>
        ) : (
          <span
            key={item}
            style={{ fontWeight: 'bold' }}
          >
            {item}
          </span>
        ),
      )}
    </BreadcrumbsBase>
  );
};

export default Breadcrumbs;
