import Router from './Router';
import { withSupabase } from './supabase';
import { withAirbrake } from './withAirbrake';
import withCustomLogic from './withCustomLogic';
import withMantine from './withMantine';
import withReactQueryProvider from './withReactQueryProvider';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';

if (import.meta.env.PROD) {
  LogRocket.init('s6dct5/clew', {
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
  });
  setupLogRocketReact(LogRocket);
}

const App = withAirbrake(
  withMantine(
    withReactQueryProvider(
      withSupabase(
        withCustomLogic(() => {
          return <Router />;
        }),
      ),
    ),
  ),
);

export default App;
