import { Notifier } from '@airbrake/browser';
import LogRocket from 'logrocket';
import React from 'react';

export const AirbrakeContext = React.createContext<Notifier | undefined>(
  undefined,
);

let contextValue: Notifier | undefined;

if (import.meta.env.PROD) {
  contextValue = new Notifier({
    environment: 'production',
    projectId: 460_193,
    projectKey: 'e5504c37bdcff0896d1029c3f6709b29',
  });

  contextValue.addFilter((notice) => {
    notice.context.sessionURL = LogRocket.sessionURL;
    return notice;
  });
} else {
  contextValue = undefined;
}

export const withAirbrake =
  (Component: React.FC): typeof Component =>
  () => {
    return (
      <AirbrakeContext.Provider value={contextValue}>
        <Component />
      </AirbrakeContext.Provider>
    );
  };
