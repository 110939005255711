import { Button, Group, Modal, Space, Text } from '@mantine/core';
import React, { useImperativeHandle } from 'react';

type DangerConfirmationApi = {
  open: () => void;
};

const DangerConfirmation = React.forwardRef<
  DangerConfirmationApi,
  React.PropsWithChildren<{
    commitDangerousActionHandler: () => void;
    title: string;
  }>
>(({ commitDangerousActionHandler, title }, ref) => {
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);

  const dialogOpenHandler = () => setIsDialogVisible(true);
  const cancelHandler = () => setIsDialogVisible(false);

  useImperativeHandle(ref, () => ({
    open: dialogOpenHandler,
  }));

  return (
    <Modal
      onClose={cancelHandler}
      opened={isDialogVisible}
      title={<Text fw={700}>{title}</Text>}
    >
      <Text>Are you sure? You can&apos;t undo this action.</Text>
      <Space h="md" />
      <Group>
        <Button onClick={cancelHandler}>Cancel</Button>
        <Button
          color="red"
          onClick={() => {
            setIsDialogVisible(false);
            commitDangerousActionHandler();
          }}
          variant="outline"
        >
          Delete
        </Button>
      </Group>
    </Modal>
  );
});

export default Object.assign(DangerConfirmation, {
  useRef: () => React.useRef<DangerConfirmationApi>(null),
});
