import * as icons from '../components/Icons';
import { LayoutUnauthenticated } from '../components/LayoutUnauthenticated';
import useUser from '../hooks/useUser';
import * as routes from '../routes';
import { debug } from '../services/debug';
import { SupabaseContext } from '../supabase';
import { AirbrakeContext } from '../withAirbrake';
import {
  Anchor,
  Box,
  Button,
  Checkbox,
  PasswordInput,
  Space,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import LogRocket from 'logrocket';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const Signup: React.FC = () => {
  const context = useContext(SupabaseContext);
  const { user } = useUser();
  const airbrake = useContext(AirbrakeContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<{
    email: string;
    options: {
      data: {
        agreeToEmails: boolean;
        agreeToTos: boolean;
      };
    };
    password: string;
    passwordConfirmation: string;
  }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(routes.home, { replace: true });
    }
  }, [navigate, user]);

  const submitHandler = handleSubmit(async (userData) => {
    if (!context) {
      return;
    }

    if (userData.password !== userData.passwordConfirmation) {
      setError('passwordConfirmation', {
        message: 'Passwords do not match',
      });
      return;
    }

    const { data, error } = await context.supabase.auth.signUp(userData);
    debug('Sign up response:', { data, error });
    if (error) {
      LogRocket.captureMessage(error.message);
      airbrake?.notify(error);
      showNotification({
        ...icons.failure,
        message: 'An error occurred. Please try again later.',
      });
    } else {
      showNotification({
        ...icons.success,
        message:
          'Account created. Please check your inbox for the confirmation e-mail.',
      });
      navigate(routes.signIn, {
        replace: true,
      });
    }
  });

  return (
    <LayoutUnauthenticated>
      <Title order={3}>Sign up</Title>
      <Space h="sm" />
      <form
        noValidate
        onSubmit={submitHandler}
      >
        <TextInput
          error={errors?.email?.message?.toString()}
          label="Email address"
          required
          type="email"
          {...register('email', {
            required: 'Please provide an email address',
          })}
        />
        <Space h="md" />
        <PasswordInput
          error={errors?.password?.message?.toString()}
          label="Password"
          required
          {...register('password', {
            required: 'Please provide a password',
          })}
        />
        <Space h="md" />
        <PasswordInput
          error={errors?.passwordConfirmation?.message?.toString()}
          label="Password Confirmation"
          required
          {...register('passwordConfirmation', {
            required: 'Please provide a password confirmation',
          })}
        />
        <Space h="xl" />
        <Checkbox
          error={errors?.options?.data?.agreeToTos?.message?.toString()}
          required
          {...register('options.data.agreeToTos', {
            required: 'You must agree to the terms of service',
          })}
          label={
            <Text>
              I agree to the{' '}
              <a
                href="https://clew.app/tos/"
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              <Box
                display="inline"
                style={{ color: 'var(--mantine-color-red-6)' }}
              >
                *
              </Box>
            </Text>
          }
        />
        <Space h="xl" />
        <Checkbox
          label="I agree to receive occasional emails from Clew"
          {...register('options.data.agreeToEmails')}
        />
        <Space h="xl" />
        <Button type="submit">Sign up</Button>
      </form>
      <Space h="xl" />
      <Anchor
        component={Link}
        to={routes.signIn}
      >
        Sign in
      </Anchor>
    </LayoutUnauthenticated>
  );
};

export default Signup;
