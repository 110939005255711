import { type Database } from '../supabase/schema';
import dayjs from 'dayjs';

type Process = Partial<Database['public']['Views']['processes_agg']['Row']>;

export const isProcessSnoozed = (process: null | Process | undefined) =>
  Boolean(
    process &&
      (process.snoozed_until === 'infinity' ||
        dayjs().isBefore(process.snoozed_until)),
  );
