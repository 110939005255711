import { type Database } from './schema';
import { createClient } from '@supabase/supabase-js';
import React from 'react';

export const SupabaseContext = React.createContext<
  | {
      supabase: ReturnType<typeof createClient<Database>>;
    }
  | undefined
>(undefined);

const contextValue = {
  supabase: createClient<Database>(
    import.meta.env.VITE_SUPABASE_URL,
    import.meta.env.VITE_SUPABASE_KEY,
  ),
};

export const withSupabase =
  (Component: React.FC): typeof Component =>
  () => {
    return (
      <SupabaseContext.Provider value={contextValue}>
        <Component />
      </SupabaseContext.Provider>
    );
  };
