import { PROCESSES_TABLE_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { type Database } from '../supabase/schema';
import { useInvalidateProcesses } from './useInvalidateProcesses';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

type Process = Database['public']['Tables']['processes']['Insert'];

export const useProcessCreate = () => {
  const context = useContext(SupabaseContext);
  const invalidateProcesses = useInvalidateProcesses();

  const { mutateAsync } = useMutation({
    mutationFn: async (parameters: { data: Process }) => {
      const { data } = parameters;
      let response;

      if (context) {
        response = await context?.supabase
          .from(PROCESSES_TABLE_NAME)
          .insert(data)
          .select('id');
        if (response && !response.error) {
          invalidateProcesses(data.parent_id);
          return {
            error: null,
            id: response.data[0].id,
          };
        }
      }

      return {
        error: response?.error.message || 'Unknown error',
      };
    },
  });

  return { createProcess: mutateAsync };
};
