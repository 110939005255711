import * as icons from '../components/Icons';
import { LayoutUnauthenticated } from '../components/LayoutUnauthenticated';
import useUser from '../hooks/useUser';
import * as routes from '../routes';
import { SupabaseContext } from '../supabase';
import { AirbrakeContext } from '../withAirbrake';
import {
  Anchor,
  Box,
  Button,
  PasswordInput,
  Space,
  TextInput,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import LogRocket from 'logrocket';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const Signin: React.FC = () => {
  const context = useContext(SupabaseContext);
  const { isLoading, user } = useUser();
  const airbrake = useContext(AirbrakeContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ email: string; password: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (user && !isLoading) {
      navigate(routes.home);
    }
  }, [isLoading, navigate, user]);

  const submitHandler = handleSubmit(async (data) => {
    if (!context) {
      return;
    }

    const { error } = await context.supabase.auth.signInWithPassword(data);
    if (error) {
      LogRocket.captureMessage(error.message);
      airbrake?.notify(error);
      showNotification({
        ...icons.failure,
        message: error.message,
      });
    } else {
      navigate(routes.home, { replace: true });
    }
  });

  if (isLoading) {
    return null;
  }

  return (
    <LayoutUnauthenticated>
      {/* <Alert
            color="yellow"
            p="lg"
            icon={<IoInformationCircleOutline size="2rem" />}
          >
            <Title order={5}>Clew is currently in closed beta.</Title>
            <Text align="justify" size="sm">
              We are currently accepting a limited number of new users on an
              invitation-only basis. If you are interested in participating in
              the closed beta, please sign up for our waitlist. We will notify
              you as soon as we have more slots available.
            </Text>
            <Space h="md" />
            <Title order={5}>Why closed beta?</Title>{' '}
            <Text align="justify" size="sm">
              We are still in the early stages of development, and we want to
              make sure that we are providing the best possible experience for
              our users. By limiting the number of users in the closed beta, we
              can focus on getting feedback from a small group of people and
              making sure that Clew is working as intended.
            </Text>
            <Space h="md" />
            <Title order={5}>What can you expect in the closed beta?</Title>
            <Text align="justify" size="sm">
              In the closed beta, you will have access to all of the features
              that are currently available in Clew. You will also be able to
              provide feedback on the app and help us to improve it. We are
              excited to share Clew with the world, and we appreciate your
              patience as we work to make it the best possible experience.
            </Text>
          </Alert> */}

      <Space h="sm" />
      <Box style={{ margin: '0 auto', maxWidth: '400px' }}>
        <Title order={3}>Sign in</Title>
        <Space h="sm" />
        <form
          noValidate
          onSubmit={submitHandler}
        >
          <TextInput
            error={errors?.email?.message?.toString()}
            label="Email address"
            type="email"
            {...register('email', {
              required: 'Please provide an email address',
            })}
          />
          <Space h="md" />
          <PasswordInput
            error={errors?.password?.message?.toString()}
            label="Password"
            {...register('password', {
              required: 'Please provide a password',
            })}
          />
          <Space h="xl" />
          <Button type="submit">Sign in</Button>
        </form>
        <Space h="xl" />
        <Anchor
          component={Link}
          to={routes.forgotPassword}
        >
          Forgot password?
        </Anchor>
        <Space h="sm" />
        <Anchor
          component={Link}
          to={routes.signUp}
        >
          Sign up
        </Anchor>
      </Box>
    </LayoutUnauthenticated>
  );
};

export default Signin;
