import { PROCESSES_AGG_VIEW_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
// import { Database } from '../supabase/schema';

// type Process = Database['public']['Views']['processes_agg']['Row'];

export const key = (id: null | string | undefined) =>
  `process-list-${id ?? 'root'}`;

export const useProcesses = (props: {
  enabled?: boolean;
  filter?: {
    isParent?: 'all' | boolean;
    isSnoozed?: 'all' | boolean;
  };
  parentId?: null | string | undefined;
}) => {
  const context = React.useContext(SupabaseContext);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    enabled: props.enabled ?? true,
    queryFn: async () => {
      if (context) {
        let { filter, parentId } = props;
        parentId ||= null;
        filter ||= {};
        filter.isSnoozed ??= 'all';
        filter.isParent ??= 'all';

        const query = context.supabase
          .from(PROCESSES_AGG_VIEW_NAME)
          .select(
            'id,title,updated_at,is_pinned,is_parent,has_incomplete_tasks,snoozed_until,context_count',
          );
        if (parentId) {
          query.eq('parent_id', parentId);
        } else {
          query.is('parent_id', null);
        }

        if (filter.isParent !== 'all') {
          query.is('is_parent', filter.isParent);
        }

        if (filter.isSnoozed === true) {
          query.gt('snoozed_until', 'now');
        }

        if (!filter.isSnoozed) {
          query.or('snoozed_until.is.null,snoozed_until.lt.now');
        }

        query.order('is_pinned', { ascending: false }).order('updated_at', {
          ascending: true,
        });

        const response = await query;
        return response.data;
      } else {
        return null;
      }
    },
    queryKey: [key(props.parentId), props.filter || {}],
    staleTime: 60_000,
  });

  const invalidate = () =>
    queryClient.invalidateQueries({ queryKey: [key(props.parentId)] });

  return {
    invalidate,
    isLoading,
    processes: data,
  };
};
