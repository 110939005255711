import { CONTEXT_TABLE_NAME, PROCESSES_AGG_VIEW_NAME } from '../constants';
import { SupabaseContext } from '../supabase';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

export const useProcess = (processId: null | string | undefined) => {
  const context = useContext(SupabaseContext);

  const { data, refetch } = useQuery({
    queryFn: async () => {
      if (!processId) {
        return null;
      }

      const processQuery = context?.supabase
        .from(PROCESSES_AGG_VIEW_NAME)
        .select('*')
        .match({ id: processId })
        .single();

      const contextQuery = context?.supabase
        .from(CONTEXT_TABLE_NAME)
        .select('*')
        .match({ [`process_id`]: processId })
        .order('created_at', {
          ascending: true,
        });

      const processData = await processQuery;
      const contextData = await contextQuery;

      return { context: contextData?.data, process: processData?.data };
    },
    queryKey: ['process', processId],
    staleTime: 60_000,
  });

  return {
    ...data,
    reloadProcess: () => {
      refetch();
    },
  };
};
