import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Notification from '../components/Notifiaction';
import { SupabaseContext } from '../supabase';
import withAuth from '../withAuth';
import { Button, Container, Space, TextInput } from '@mantine/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

const ResetPassword = withAuth(() => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const context = useContext(SupabaseContext);
  const [successMessage, setSuccessMessage] = React.useState('');

  const submitHandler = handleSubmit((data) => {
    if (data.password !== data.password_confirmation) {
      setError('password_confirmation', {
        message: 'Passwords do not match',
      });
      return;
    }

    context?.supabase.auth
      .updateUser({ password: data.password })
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((response) => {
        if (response.error) {
          setError('password', {
            message: response.error.message,
          });
        } else {
          setSuccessMessage('Password updated successfully');
          reset();
        }
      });
  });

  return (
    <Layout>
      <Notification successMessage={successMessage} />
      <Container size="md">
        <Breadcrumbs items={[{ '/': 'Home' }, 'Reset password']} />
        <Container
          size="xs"
          style={{ padding: 40 }}
        >
          <form
            noValidate
            onSubmit={submitHandler}
          >
            <TextInput
              error={errors?.password?.message?.toString()}
              label="Password"
              type="password"
              {...register('password', {
                required: 'This field is required',
              })}
            />
            <Space h="md" />
            <TextInput
              error={errors?.password_confirmation?.message?.toString()}
              label="Password confirmation"
              type="password"
              {...register('password_confirmation', {
                required: 'This field is required',
              })}
            />
            <Space h="md" />
            <Button type="submit">Save</Button>
          </form>
        </Container>
      </Container>
    </Layout>
  );
});

export default ResetPassword;
