import { isProcessSnoozed } from '../services/isProcessSnoozed';
import { type Database } from '../supabase/schema';
import { Text, UnstyledButton } from '@mantine/core';
import cx from 'clsx';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useMemo } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import {
  IoChatboxOutline,
  IoDocumentOutline,
  IoFolderOpenOutline,
  IoStar,
  IoTimerOutline,
} from 'react-icons/io5';
import { RiListCheck2 } from 'react-icons/ri';

dayjs.extend(localizedFormat);

// type Process = NonNullable<ReturnType<typeof useProcesses>['processes']>[0];

type ProcessItemProps = {
  idx: number;
  onClick: () => void;
  process: Partial<Database['public']['Views']['processes_agg']['Row']>;
};
const starSizes = ['text-xl', 'text-lg', 'text-md', 'text-sm', 'text-xs'];

export const ProcessItem = ({ idx, onClick, process }: ProcessItemProps) => {
  const [updatedAt, snoozedUntil] = useMemo(
    () => [dayjs(process.updated_at), dayjs(process.snoozed_until)],
    [process.snoozed_until, process.updated_at],
  );
  const isSnoozed = useMemo(() => isProcessSnoozed(process), [process]);
  const Icon = process.is_parent ? IoFolderOpenOutline : IoDocumentOutline;

  let starOrPin = null;
  if (!isSnoozed && idx < 5) {
    starOrPin = (
      <IoStar
        className={cx('absolute -ml-[25px] self-center', starSizes[idx])}
        color="#ddd353"
      />
    );
  }

  if (process.is_pinned && !isSnoozed) {
    starOrPin = (
      <BsPinAngleFill
        className="absolute -ml-[25px] self-center opacity-70"
        color="#ff8300"
      />
    );
  }

  return (
    <UnstyledButton
      className="w-full flex gap-2 justify-between py-4 px-0 border-b border-slate-300 border-solid [&:last-of-type]:border-none"
      onClick={onClick}
    >
      {starOrPin}
      <Text className="grow [&_>_*]:inline-block">
        <Icon
          className="mr-2 align-text-bottom"
          color="#2090C0"
        />

        {process.title}

        {(process.context_count || 0) > 0 && (
          <span className="inline-block text-slate-900 opacity-40 text-sm ml-3 align-baseline">
            {process.context_count}
            <IoChatboxOutline className="inline-block ml-[2px]" />
          </span>
        )}

        {process.has_incomplete_tasks && (
          <RiListCheck2
            className="opacity-60 ml-3"
            color="red"
            title="Has incomplete tasks"
          />
        )}
      </Text>
      {isSnoozed ? (
        <Text
          c="rgba(0, 10, 20, .4)"
          title={`Snoozed ${
            process.snoozed_until === 'infinity'
              ? 'forever'
              : `until: ${snoozedUntil.format('LLL')}`
          }\nLast modified: ${updatedAt.format('LLL')}`}
        >
          <IoTimerOutline className="mr-[2px] -mb-[2px] ml-3 inline-block align-baseline" />
          {process.snoozed_until === 'infinity'
            ? '∞'
            : snoozedUntil.format('MMM DD')}
        </Text>
      ) : (
        <Text
          c="rgba(0, 10, 20, .4)"
          className="whitespace-nowrap"
          title={updatedAt.format('LLL')}
        >
          {dayjs().year() === updatedAt.year()
            ? updatedAt.format('MMM DD')
            : updatedAt.format('MMM DD, YYYY')}
        </Text>
      )}
    </UnstyledButton>
  );
};
