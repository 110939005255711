import { SupabaseContext } from '../supabase';
import { type User } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

const useUser = () => {
  const context = React.useContext(SupabaseContext);

  const { data, isLoading, refetch } = useQuery<null | undefined | User>({
    queryFn: async () => {
      const response = await context?.supabase.auth.getUser();
      return response?.data?.user;
    },
    queryKey: ['current user'],
    staleTime: 60_000,
  });

  return { isLoading, refetch, user: data };
};

export default useUser;
