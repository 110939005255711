import { Notification as NotificationBase } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { IoAlert } from 'react-icons/io5';

export type NotificationRefApi = {
  setFailureMessage: (failureMessage: string | undefined) => void;
  setSuccessMessage: (successMessage: string | undefined) => void;
};

const Notification = React.forwardRef<
  NotificationRefApi,
  React.PropsWithChildren<{
    failureMessage?: string;
    successMessage?: string;
  }>
>(({ failureMessage, successMessage }, ref) => {
  const [messages, setMessages] = useSetState({
    failureMessage,
    successMessage,
  });

  useEffect(() => {
    setMessages({ successMessage });
  }, [setMessages, successMessage]);

  useEffect(() => {
    setMessages({ failureMessage });
  }, [failureMessage, setMessages]);

  React.useImperativeHandle(ref, () => ({
    setFailureMessage: (failureMessage_: string | undefined) => {
      setMessages({ failureMessage: failureMessage_ });
    },
    setSuccessMessage: (successMessage_: string | undefined) => {
      setMessages({ successMessage: successMessage_ });
    },
  }));

  if (messages.successMessage) {
    return (
      <NotificationBase
        onClose={() => setMessages({ successMessage: undefined })}
        style={{
          left: '50%',
          minWidth: '350px',
          position: 'absolute',
          top: '20px',
          transform: 'translateX(-50%)',
        }}
      >
        {messages.successMessage}
      </NotificationBase>
    );
  }

  if (messages.failureMessage) {
    return (
      <NotificationBase
        color="red"
        icon={<IoAlert size={20} />}
        onClose={() => setMessages({ failureMessage: undefined })}
        style={{
          left: '50%',
          minWidth: '350px',
          position: 'absolute',
          top: '20px',
          transform: 'translateX(-50%)',
        }}
      >
        {messages.failureMessage}
      </NotificationBase>
    );
  }

  return null;
});

export default Object.assign(Notification, {
  useRef: () => React.useRef<NotificationRefApi>(null),
});
