import {
  Anchor,
  Box,
  Button,
  type MantineStyleProp,
  TextInput,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type InlineEditProps = {
  defaultValue: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onStatusChange?: (status: 'default' | 'editing') => void;
  renderDefault: (value: string) => React.ReactNode;
  style: MantineStyleProp;
};

export const InlineEditable: React.FC<InlineEditProps> = ({
  defaultValue,
  disabled,
  onChange,
  onStatusChange,
  renderDefault,
  style,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const clickOutsideRef = useClickOutside(() => setIsEditing(false));

  const { handleSubmit, register } = useForm({
    defaultValues: { value: defaultValue },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (data.value) {
      onChange(data.value);
    }

    setIsEditing(false);
  });

  useEffect(() => {
    onStatusChange?.(isEditing ? 'editing' : 'default');
  }, [isEditing, onStatusChange]);

  return isEditing ? (
    <Box style={style}>
      <form
        noValidate={false}
        onSubmit={onSubmit}
        ref={clickOutsideRef}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
        }}
      >
        <TextInput
          {...register('value')}
          autoFocus
          style={{ flexGrow: 1 }}
        />

        <Button type="submit">Save</Button>
        <Anchor
          component="button"
          onClick={() => setIsEditing(false)}
          size="sm"
          type="button"
        >
          Cancel
        </Anchor>
      </form>
    </Box>
  ) : (
    <Box
      onDoubleClick={() => {
        if (!disabled) {
          setIsEditing(true);
        }
      }}
      style={style}
    >
      {renderDefault(defaultValue)}
    </Box>
  );
};
