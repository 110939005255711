import * as icons from '../components/Icons';
import { LayoutUnauthenticated } from '../components/LayoutUnauthenticated';
import * as routes from '../routes';
import { SupabaseContext } from '../supabase';
import { Anchor, Button, Space, TextInput, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const context = useContext(SupabaseContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  const navigate = useNavigate();

  const submitHandler = handleSubmit(async (data) => {
    if (!context) {
      return;
    }

    const response = await context?.supabase.functions.invoke(
      'clew-server/send-password-reminder',
      {
        body: data,
      },
    );

    if (!response || response.data.error) {
      showNotification({
        ...icons.failure,
        message: response.data.error.message,
      });
    } else {
      showNotification({
        ...icons.success,
        message: 'Password reset email sent',
      });

      navigate(routes.signIn, {
        replace: true,
      });
    }
  });

  return (
    <LayoutUnauthenticated>
      <Title order={3}>Password recovery</Title>
      <Space h="sm" />
      <form
        noValidate
        onSubmit={submitHandler}
      >
        <TextInput
          error={errors?.email?.message?.toString()}
          label="Email address"
          type="email"
          {...register('email', {
            required: 'Please provide an email address',
          })}
        />
        <Space h="md" />
        <Button type="submit">Submit</Button>
      </form>
      <Space h="xl" />
      <Anchor
        component={Link}
        to={routes.signIn}
      >
        Sign in
      </Anchor>
    </LayoutUnauthenticated>
  );
};

export default ForgotPassword;
